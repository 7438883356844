// auth.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatAll, map, switchMap, tap } from 'rxjs/operators';
import { login, logout, refreshToken, setBearerToken, setUserInfo } from '../actions/auth.actions';
import { IdentityService } from '../../services/identity.service';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap(() => this.authService.handleLogin()),
      switchMap((token) => {
        return this.authService.getUserInfo().pipe(
          map((userInfo) => {
            return [setBearerToken({ token }), setUserInfo({ userInfo })];
          }),
          concatAll()
        );
      })
    )
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshToken),
      switchMap((currentToken) => this.authService.handleToken('refresh_token', currentToken.refreshToken)),
      map((token) => {
        sessionStorage.setItem('bearerToken', JSON.stringify(token));
        return setBearerToken({ token });
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      tap(() => {
        this.authService.logout();
      })
    )
  );

  constructor(private actions$: Actions, private authService: IdentityService) {}
}
