<div class="container">
  <div class="header-top">
    <div class="header-top-row">
      <h3>{{ getHeaderText() }}</h3>
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div *ngIf="getInvalidUsersCount() > 0" class="warning-external">
      <div class="alert alert-danger text-dark fade show" role="alert">
        <i class="modus-icons mr-1" aria-hidden="true">warning</i>
        <div *ngIf="getInvalidUsersCount() === 1; else multipleUserError">
          This user could not be added. The user must create a Tekla Online profile at
          <a href="{{ atcUrl }}">{{ atcUrl }}</a> before you add the user.
        </div>
        <ng-template #multipleUserError>
          <div>
            These users could not be added. The users must create a Tekla Online profile at
            <a href="{{ atcUrl }}">{{ atcUrl }}</a> before you add them.
          </div>
        </ng-template>
      </div>
    </div>
    <div *ngIf="showExternalEditorWarning()" class="warning-external">
      <div class="alert alert-warning text-dark fade show" role="alert">
        <i class="modus-icons mr-1" aria-hidden="true">warning</i>
        <div>
          Be aware that you are giving editor access to a user with a different domain email address to your own
          (&#64;{{ extractDomain(adminEmail) }}).
        </div>
      </div>
    </div>
  </div>
  <app-reusable-table
    class="reusable-table"
    [columns]="columns"
    [data]="tableData"
    [searchEnabled]="true"
    [sortEnabled]="true"
    [paginationEnabled]="true"
    [isLoading]="isLoading"
    tableSize="small"
    [headerTemplate]="headerTemplate"
    [(selectedRows)]="selectedRows"
    [filterFields]="filterFields"
    [rowClassFunction]="isPendingRow"
  ></app-reusable-table>
  <div class="modal-footer">
    <app-button [buttonClass]="'btn-secondary'" [buttonText]="cancelText" (clickEvent)="onClose()" />
    <app-button
      [buttonClass]="'btn-primary'"
      [buttonText]="confirmText"
      [buttonEnabled]="hasChanges()"
      [spinnerEnabled]="true"
      [inProgress]="confirmInProgress"
      (clickEvent)="confirmChanges()"
    />
  </div>
</div>

<!--Templates-->
<ng-template #headerTemplate>
  <div class="header-container">
    <div class="header-row">
      <input
        type="text"
        class="form-control email-input"
        id="userEmail"
        placeholder="New user email"
        [(ngModel)]="userEmail"
        (input)="emailValid = isValidEmail()"
      />
      <app-role-dropdown
        class="new-user-role-dropdown"
        [role]="newUserRole"
        (roleChange)="newUserRoleChange($event)"
        [btnClass]="'btn btn-outline-secondary dropdown-toggle simple-select'"
      ></app-role-dropdown>
      <app-button
        [buttonClass]="'btn-primary'"
        [spinnerEnabled]="true"
        [buttonEnabled]="emailValid && newUserRole !== 'Select role'"
        [buttonText]="addUserText"
        [spinnerEnabled]="true"
        [inProgress]="addUserInProgress"
        (clickEvent)="addUser()"
      />
      <app-button
        [buttonClass]="'btn-danger'"
        [buttonEnabled]="selectedRows.length > 0"
        [buttonText]="removeAccessText"
        [spinnerEnabled]="true"
        [inProgress]="removeAccessInProgress"
        (clickEvent)="removeAccess()"
      />
    </div>
    <div class="header-row">
      <div class="chips-container mt-2">
        <div
          *ngFor="let pendingUser of emailChips"
          class="chip chip-outline"
          [ngClass]="pendingUser.validUser ? (pendingUser.external ? 'chip-external' : 'chip-internal') : 'chip-error'"
        >
          {{ pendingUser.displayName }}
          <div (click)="removePendingUser(pendingUser.email)" class="chip-delete-right">
            <i class="modus-icons notranslate" aria-hidden="true">cancel_circle</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #roleTemplate let-rowData="rowData">
  <app-role-dropdown [role]="rowData.permissionLevel" (roleChange)="onRoleChange($event, rowData)"></app-role-dropdown>
</ng-template>

<ng-template #headerTooltipTemplate let-col>
  <div style="display: flex; align-items: center">
    {{ col.header }}
    <i
      [pTooltip]="tooltipContent"
      tooltipPosition="bottom"
      class="modus-icons"
      aria-hidden="true"
      style="margin-left: 5px; color: #0063a3"
      >info_outlined</i
    >
  </div>
</ng-template>

<ng-template #tooltipContent>
  <div class="flex align-items-center tooltip-content">
    <ul>
      <li>
        <strong>Editor:</strong> Can edit collection contents, such as adding drawings to libraries, moving drawings
        between libraries, change library names, etc.
      </li>
      <li>
        <strong>Viewer:</strong> Can only use Collections to create drawings from them. Can view collection contents,
        but cannot do any changes to them.
      </li>
    </ul>
  </div>
</ng-template>
