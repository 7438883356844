import { createSelector } from '@ngrx/store';
import { AppState } from '../states/app.state';

export const selectUserInfo = (state: AppState) => state;

export const selectUserInfoObject = createSelector(
    selectUserInfo,
  (state: AppState) => state.auth.userInfo
);

export const authSelectors = {
  selectUserInfoObject
}