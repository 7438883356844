import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserCollectionsComponent } from '../user-collections/user-collections.component';
import { AddUserComponent } from '../add-user/add-user.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { DrawingCloneService } from '../../services/drawing-clone.service';
import { Observable, catchError, of, tap } from 'rxjs';
import { UserEntity } from '../../models/user.entity';
import { ReusableTableComponent } from '../reusable-table/reusable-table.component';
import { RoleDropdownComponent } from '../role-dropdown/role-dropdown.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Column } from '../../models/column.model';
import { RemoveAccessComponent } from '../remove-access/remove-access.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/states/app.state';
import { selectUserInfoObject } from '../../ngrx/selectors/auth.selector';
import { Router } from '@angular/router';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [ButtonComponent, ReusableTableComponent, RoleDropdownComponent, NgIf, NgFor, AsyncPipe],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
  providers: [DrawingCloneService],
})
export class UsersComponent implements AfterViewInit, OnInit {
  bsModalRef?: BsModalRef;
  users$?: Observable<UserEntity[]>;
  columns: Column[];
  filterFields: string[];
  isLoading = true;
  selectedRows: UserEntity[] = [];
  isAdmin: boolean;
  addNewUserText = 'Add new user';
  removeAccessText = 'Remove access';

  @ViewChild('collectionsAssignedTemplate') collectionsAssignedTemplate: TemplateRef<any>;

  constructor(
    private drawingCloneService: DrawingCloneService,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    public router: Router
  ) {}

  ngOnInit() {
    this.store.select(selectUserInfoObject).subscribe((userInfo) => {
      this.isAdmin = userInfo && userInfo.organizationRole === 'Admin';
    });
    this.loadUserData();
    this.filterFields = ['displayName', 'userName', 'companyName'];
  }

  ngAfterViewInit() {
    this.columns = [
      { header: 'User', key: 'displayName' },
      { header: 'Email', key: 'userName' },
      { header: 'Company', key: 'companyName' },
      { header: 'Collections assigned', key: 'collectionsAssigned', template: this.collectionsAssignedTemplate },
    ];

    this.cdr.detectChanges();
  }

  openDialog(user: UserEntity): void {
    const initialState: ModalOptions = {
      initialState: {
        userId: user.userGuid,
        userMail: user.userName,
        userName: user.displayName,
      },
    };
    this.bsModalRef = this.modalService.show(UserCollectionsComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.changesMade.subscribe(() => {
      this.selectedRows = [];
      this.isLoading = true;
      this.loadUserData();
    });
  }

  openAddUserDialog(): void {
    const initialState: ModalOptions = {
      initialState: {
        user: ['Open a modal with component', 'Pass your data', 'Do something else', '...'],
      },
    };
    this.bsModalRef = this.modalService.show(AddUserComponent, initialState);
    this.bsModalRef.content.usersAdded.subscribe(() => {
      this.selectedRows = [];
      this.isLoading = true;
      this.loadUserData();
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openRemoveUserAccessDialog(): void {
    const initialState: ModalOptions = {
      initialState: { title: 'Remove Access', userIds: this.selectedRows.map((user) => user.userGuid) },
    };
    this.bsModalRef = this.modalService.show(RemoveAccessComponent, initialState);
    this.bsModalRef.content.accessRemoved.subscribe(() => {
      this.selectedRows = [];
      this.isLoading = true;
      this.loadUserData();
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  loadUserData(): void {
    this.users$ = this.drawingCloneService.getCompanyCollectionsUsers().pipe(
      tap(() => (this.isLoading = false)),
      catchError((error) => {
        this.isLoading = false;
        console.log('Failed to load collections', error);
        return of([]);
      })
    );
  }

  redirect() {
    this.router.navigate(['/']);
  }
}
