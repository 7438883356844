<div class="position-relative">
  <p-dropdown class="dropdown-pagination" [options]="rowsPerPageOptions" (onChange)="onRowsChange($event)"></p-dropdown>

  <p-table
    #reusableTable
    [value]="isLoading ? [] : data"
    [paginator]="paginationEnabled"
    [rows]="10"
    [showCurrentPageReport]="true"
    [selectionPageOnly]="true"
    [scrollable]="true"
    [filterDelay]="0"
    [pageLinks]="4"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="filterFields"
    [loading]="isLoading"
    [showLoader]="false"
    [styleClass]="getStyleClass()"
    [scrollHeight]="getScrollHeight()"
    (selectionChange)="onSelectionChange($event)"
    [(selection)]="selectedRows"
  >
    <ng-template pTemplate="caption" *ngIf="searchEnabled">
      <div class="table-header-info">
        <div class="info-header-container">
          <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
          <div class="form-group search-bar-container">
            <div class="input-with-icon-left">
              <input
                class="form-control search-input"
                id="Input3"
                [(ngModel)]="searchTerm"
                (input)="reusableTable.filterGlobal($any($event.target).value, 'contains')"
                placeholder="Search"
              />
              <div class="input-icon">
                <i class="modus-icons notranslate" aria-hidden="true">search</i>
              </div>
              <button type="button" class="close" aria-label="Close" (click)="onClearInput()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th id="select-all-header" style="width: 3em">
          <ng-container *ngIf="showSelectAllCheckbox; else notHeaderCheckbox">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </ng-container>
          <ng-template #notHeaderCheckbox>
            <p-checkbox binary="true" [ngModel]="isChecked()" (onChange)="customSelectAll($event)"></p-checkbox>
          </ng-template>
        </th>
        <th *ngFor="let col of columns" [pSortableColumn]="sortEnabled ? col.key : null" style="padding: 8px" scope="col">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <ng-container
              *ngIf="col.headerTemplate; else defaultHeader"
              [ngTemplateOutlet]="col.headerTemplate"
              [ngTemplateOutletContext]="{ $implicit: col }"
            ></ng-container>
            <ng-template #defaultHeader>{{ col.header }}</ng-template>
            <p-sortIcon *ngIf="sortEnabled" [field]="col.key"></p-sortIcon>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [ngClass]="rowClassFunction(rowData)">
        <td>
          <p-tableCheckbox
            [ngClass]="{ 'not-allowed': isRowDisabled && isRowDisabled(rowData) }"
            [title]="
              isRowDisabled && isRowDisabled(rowData)
                ? 'Only editors can view and manage users for this collection.'
                : ''
            "
            [disabled]="isRowDisabled && isRowDisabled(rowData)"
            [value]="rowData"
          >
          </p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns">
          <ng-container *ngIf="col.template; else defaultTemplate">
            <ng-container
              *ngTemplateOutlet="col.template; context: { $implicit: rowData[col.key], rowData: rowData }"
            ></ng-container>
          </ng-container>
          <ng-template #defaultTemplate>{{ rowData[col.key] }}</ng-template>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      <tr *ngFor="let row of [].constructor(rows)">
        <td>
          <p-skeleton class="loading-skeleton" styleClass="mb-2"></p-skeleton>
        </td>
        <td *ngFor="let col of columns">
          <p-skeleton class="loading-skeleton" styleClass="mb-2"></p-skeleton>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7" style="text-align: center">No records found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
