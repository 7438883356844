import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable, map } from 'rxjs';
import { UserEntity } from '../models/user.entity';
import { CollectionEntity, CollectionEntityWithPermission } from '../models/collection.entity';
import { UserCollectionRoleEntity } from '../models/user-collection-role.entity';
import { Permission } from '../models/permission';

@Injectable({
  providedIn: 'root',
})
export class DrawingCloneService {
  constructor(private httpClient: HttpClient) {}

  /// <summary>
  /// Get a user by its guid.
  /// </summary>
  public getUser(userGuid: string): Observable<UserEntity> {
    return this.httpClient.get<UserEntity>(`${environment.drawingCloneServiceUrl}/User/${userGuid}`);
  }

  /// <summary>
  /// Get company collections users.
  /// </summary>
  public getCompanyCollectionsUsers(): Observable<UserEntity[]> {
    return this.httpClient
      .get<{ userEntities: UserEntity[] }>(`${environment.drawingCloneServiceUrl}/User/companyCollectionsUsers`)
      .pipe(map((response) => response.userEntities));
  }

  /// <summary>
  /// Get all collections of the logged user.
  /// </summary>
  public getUserCollections(withPermissions: boolean = false): Observable<CollectionEntity[]> {
    return this.httpClient
      .get<{ collectionEntities: CollectionEntity[] }>(
        `${environment.drawingCloneServiceUrl}/User/collections?withPermissions=${withPermissions}`
      )
      .pipe(map((response) => response.collectionEntities));
  }

  /// <summary>
  /// Get all collections of the logged user.
  /// </summary>
  public getAssignedCollections(): Observable<CollectionEntityWithPermission[]> {
    return this.httpClient
      .get<{ collectionEntities: CollectionEntityWithPermission[] }>(
        `${environment.drawingCloneServiceUrl}/User/assignedCollections`
      )
      .pipe(map((response) => response.collectionEntities));
  }

  /// <summary>
  /// Get all collections of the specified user and the corresponding level of access.
  /// </summary>
  public getUserCollectionsWithPermission(userId: string): Observable<CollectionEntityWithPermission[]> {
    const params = new HttpParams().set('userId', userId);
    return this.httpClient
      .get<{ collectionEntities: CollectionEntityWithPermission[] }>(
        `${environment.drawingCloneServiceUrl}/User/usercollections`,
        { params }
      )
      .pipe(map((response) => response.collectionEntities));
  }

  /// <summary>
  /// Get all collections of the specified user and the corresponding level of access.
  /// </summary>
  public getPermissionsForCollections(collectionGuids: string[]): Observable<UserCollectionRoleEntity[]> {
    return this.httpClient
      .post<{ collectionEntities: UserCollectionRoleEntity[] }>(
        `${environment.drawingCloneServiceUrl}/Collection/Permissions`,
        collectionGuids
      )
      .pipe(
        map((response) => {
          return response.collectionEntities.map((entity) => ({
            ...entity,
            initialPermissionLevel: entity.permissionLevel,
          }));
        })
      );
  }

  /// <summary>
  /// Assign permissions to a list of users.
  /// </summary>
  public assignPermissions(userEmails: string[], permissions: Permission[]): Observable<any> {
    const requestBody = {
      userEmails: userEmails,
      permissions: permissions,
    };

    return this.httpClient
      .post(`${environment.drawingCloneServiceUrl}/User/Permissions`, requestBody)
      .pipe(map((response) => response));
  }

  /// <summary>
  /// Make a collections public.
  /// </summary>
  public changeCollectionsAccess(collectionsAccess: {collectionGuid: string, access: string}[]): Observable<any> {
    return this.httpClient.post(`${environment.drawingCloneServiceUrl}/Collection/Access`, collectionsAccess);
  }

  /// <summary>
  /// Remove a collection by its identifier.
  /// </summary>
  public removeCollection(collectionGuid: string): Observable<any> {
    return this.httpClient.delete(`${environment.drawingCloneServiceUrl}/Collection/${collectionGuid}`);
  }

  /// <summary>
  /// Get a user information by its email.
  /// </summary>
  public getUserInfo(userEmail: string): Observable<UserEntity> {
    return this.httpClient.get<UserEntity>(
      `${environment.drawingCloneServiceUrl}/User/GetUserInfo?userEmail=${userEmail}`
    );
  }

  /// <summary>
  /// Removes one or more permissions
  /// </summary>
  public removePermissions(collectionIds: string[], userId: string): Observable<any> {
    const requestBody = {
      CollectionIds: collectionIds,
      UserId: userId,
    };

    return this.httpClient
      .delete(`${environment.drawingCloneServiceUrl}/User/permissions`, { body: requestBody })
      .pipe(map((response) => response));
  }
}
