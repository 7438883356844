import { AsyncPipe, CommonModule, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { PrimeIcons } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';
import { Column } from '../../models/column.model';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-reusable-table',
  standalone: true,
  imports: [
    TableModule,
    AsyncPipe,
    InputTextModule,
    NgFor,
    NgIf,
    NgTemplateOutlet,
    DropdownModule,
    FormsModule,
    CommonModule,
    CheckboxModule,
    SkeletonModule
  ],
  providers: [PrimeIcons],
  templateUrl: './reusable-table.component.html',
  styleUrl: './reusable-table.component.scss',
})
export class ReusableTableComponent {
  @ViewChild('reusableTable') table: Table;
  @Input() headerTemplate: TemplateRef<any>;
  @Input() columns: Column[];
  @Input() data: any[];
  @Input() searchEnabled = false;
  @Input() sortEnabled = false;
  @Input() paginationEnabled = false;
  @Input() isLoading = true;
  @Input() filterFields: string[];
  @Input() tableSize: 'small' | 'medium' | 'large' = 'medium';
  @Input() selectedRows: any[] = [];
  @Output() selectedRowsChange = new EventEmitter<any[]>();
  @Input() rowClassFunction: (row: any) => string = () => '';
  @Input() isRowDisabled: (rowData: any) => boolean;
  @Input() showSelectAllCheckbox = true;
  checkedPagesList: any[] = [];

  rowsPerPageOptions = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: 'All', value: null },
  ];
  rows = this.rowsPerPageOptions[0].value;
  searchTerm: string = '';

  onSelectionChange(rows: any[]) {
    this.selectedRowsChange.emit(rows);
  }

  getScrollHeight(): string {
    const screenHeight = window.innerHeight;
    switch (this.tableSize) {
      case 'small':
        return `${screenHeight * 0.5}px`;
      case 'medium':
        return `${screenHeight * 0.6}px`;
      default:
        return `${screenHeight * 0.8}px`;
    }
  }

  getStyleClass(): string {
    let styleClass = 'p-datatable-gridlines ';
    switch (this.tableSize) {
      case 'small':
        styleClass += 'p-datatable-sm';
        break;
      case 'medium':
        styleClass += 'p-datatable-md';
        break;
      default:
        styleClass += 'p-datatable-lg';
    }
    return styleClass;
  }

  onRowsChange(event) {
    let rowsPerPage = event.value;
    const totalEntries = this.table.value.length;

    if (rowsPerPage === null) {
      rowsPerPage = totalEntries;
      this.table.first = 0;
    } else {
      const firstRowIndex = this.table.first;
      let newCurrentPage = Math.ceil((firstRowIndex + 1) / rowsPerPage);
      const totalPages = Math.ceil(totalEntries / rowsPerPage);

      if (newCurrentPage > totalPages) {
        newCurrentPage = totalPages;
      }
      const firstRow = (newCurrentPage - 1) * rowsPerPage;
      this.table.first = firstRow;
    }
    this.table.rows = rowsPerPage;
  }

  onClearInput() {
    this.searchTerm = '';
    this.table.filterGlobal('', 'contains');
  }

  isChecked(): boolean {
    const currentPage = this.getCurrentPage();
    return this.checkedPagesList.includes(currentPage);
  }

  customSelectAll(event: any) {
    const selectAllChecked = event.checked;
    const currentPage = this.getCurrentPage();

    const startIndex = (currentPage - 1) * this.table.rows;
    const endIndex = Math.min(startIndex + this.table.rows, this.data.length);
    const currentPageRows = this.data.slice(startIndex, endIndex).filter((row) => !this.isRowDisabled(row));

    if (selectAllChecked) {
      this.selectedRows = [...this.selectedRows, ...currentPageRows];
      this.checkedPagesList.push(currentPage);
    } else {
      this.selectedRows = this.selectedRows.filter((row) => !currentPageRows.includes(row));
      this.checkedPagesList = this.checkedPagesList.filter((page) => page !== currentPage);
    }

    this.selectedRowsChange.emit(this.selectedRows);
  }

  getCurrentPage(): number {
    return this.table?.first / this.table?.rows + 1;
  }
}
