import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/states/app.state';
import { UserInfo } from '../../models/user.info';
import { setSuperAdminSwitch, setUserInfo } from '../../ngrx/actions/auth.actions';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-role-switch',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './role-switch.component.html',
  styleUrl: './role-switch.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class RoleSwitchComponent {
  switchValue: boolean;

  constructor(private store: Store<AppState>) {
    this.store.select('auth').subscribe((auth) => {
      this.switchValue = auth.superAdminSwitch;
    });
  }

  onRoleChange(event: Event): void {
    this.store.dispatch(setSuperAdminSwitch({ superAdminSwitch: this.switchValue }));
  }
}
