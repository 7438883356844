import { Component, OnInit, ViewChild } from '@angular/core';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '../button/button.component';
import { ErrorOverlayType } from '../../helpers/error-overlay.enum.';

@Component({
  selector: 'app-exception-overlay',
  standalone: true,
  imports: [CommonModule, ButtonComponent, FormsModule],
  templateUrl: './exception-overlay.component.html',
  styleUrl: './exception-overlay.component.scss'
})
export class ExceptionOverlayComponent implements OnInit { 
  showError = false;
  canRetry = false;
  overlayType = ErrorOverlayType.Toast;
  errorMessage = 'An error occurred. Please try again later.';

  constructor(private errorHandlingService: ErrorHandlingService) {}

  ngOnInit() {
    this.errorHandlingService.showError$.subscribe(error => {
      this.showError = !!error;
      if(this.showError) {
        this.errorMessage = error.message;
        this.overlayType = error.overlay;
        this.canRetry = error.canRetry;
      }
    });
  }

  onRetry() {
    this.showError = false;
    this.errorHandlingService.refreshComponent();
  }

  onClose(clickedElement: HTMLElement) {
    if (clickedElement) {
      setTimeout(() => {
        this.showError = false;
      }, 300);
      clickedElement.classList.replace('fade-in', 'fade-out');
    }
  }
}