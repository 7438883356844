import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { RoleDropdownComponent } from '../role-dropdown/role-dropdown.component';
import { Column } from '../../models/column.model';
import { CollectionEntityWithPermission } from '../../models/collection.entity';
import { ReusableTableComponent } from '../reusable-table/reusable-table.component';
import { CommonModule } from '@angular/common';
import { Observable, catchError, finalize, first, firstValueFrom, forkJoin, from, mergeMap, of, scan, tap } from 'rxjs';
import { DrawingCloneService } from '../../services/drawing-clone.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-remove-access',
  standalone: true,
  imports: [ReusableTableComponent, RoleDropdownComponent, CommonModule, ButtonComponent],
  templateUrl: './remove-access.component.html',
  styleUrl: './remove-access.component.scss',
})
export class RemoveAccessComponent {
  @ViewChild('roleTemplate') roleTemplate: TemplateRef<any>;
  @ViewChild('emailTemplate') emailTemplate: TemplateRef<any>;
  @ViewChild('createdAtTemplate') createdAtTemplate: TemplateRef<any>;
  @ViewChild('lastModifiedAtTemplate') lastModifiedAtTemplate: TemplateRef<any>;
  @ViewChild(ReusableTableComponent) tableComponent: ReusableTableComponent;

  tableData$: Observable<CollectionEntityWithPermission[]>;
  selectedRows: any[] = [];
  columns: Column[];
  isLoading: boolean = false;
  filterFields: string[];
  usersEmails: string[];
  userIds: string[];
  initialHeaderMessage: string = 'The access to the selected collections will be removed';
  gridHeaderMessage: string;
  cancelText: string = 'Cancel';
  removeText: string = 'Remove';
  removeInProgress: boolean = false;
  shouldUpdateTable: boolean = false;
  @Output() accessRemoved = new EventEmitter<void>();

  constructor(public dialogRef: BsModalRef, private drawingCloneService: DrawingCloneService) {
    this.dialogRef.onHidden.subscribe(() => {
      if (this.shouldUpdateTable) {
        this.accessRemoved.emit();
      }
    });
  }

  retrievePermissions() {
    this.tableData$ = of([]);
    this.isLoading = true;
    from(this.userIds)
      .pipe(
        mergeMap((userId) =>
          this.drawingCloneService.getUserCollectionsWithPermission(userId).pipe(
            first(),
            catchError((error) => {
              // handle error (display notification?)
              return of([]);
            })
          )
        ),
        scan((acc, val) => acc.concat(val), []),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((data) => {
        this.tableData$ = of(data);
      });
  }

  ngOnInit() {
    this.retrievePermissions();
    this.gridHeaderMessage = this.initialHeaderMessage;
    this.filterFields = ['title', 'permission.permissionLevel'];
  }

  ngAfterViewInit(): void {
    this.columns = [
      { header: 'Collection Name', key: 'title' },
      { header: 'Email', key: 'permission.userId', template: this.emailTemplate },
      { header: 'Created On', key: 'createdOnEpoch', template: this.createdAtTemplate },
      { header: 'Last Modified', key: 'modifiedOnEpoch', template: this.lastModifiedAtTemplate },
      { header: 'Role', key: 'role', template: this.roleTemplate },
    ];
  }

  selectionChanged($event) {
    this.selectedRows = $event;

    if (this.selectedRows.length === 0) {
      this.gridHeaderMessage = this.initialHeaderMessage;
    } else {
      this.gridHeaderMessage = `${this.selectedRows.length} collections selected`;
    }
  }

  onClose(): void {
    this.dialogRef.hide();
  }

  async removePermissions() {
    this.removeInProgress = true;
    const deleteAssignments = new Map<string, string[]>();

    this.selectedRows.forEach((row) => {
      const userId = row.permission.userId;
      const collectionGuid = row.collectionGuid;

      if (deleteAssignments.has(userId)) {
        deleteAssignments.get(userId)?.push(collectionGuid);
      } else {
        deleteAssignments.set(userId, [collectionGuid]);
      }
    });

    const observables = Array.from(deleteAssignments.entries()).map(([userId, collectionIds]) => {
      return this.drawingCloneService.removePermissions(collectionIds, userId).pipe(
        first(),
        catchError((error) => {
          // Handle error
          return of(error); // Ensure the observable chain continues in case of error
        })
      );
    });

    try {
      await firstValueFrom(forkJoin(observables));
    } catch (error) {
      // handle error (display notification?)
    } finally {
      this.retrievePermissions();

      this.shouldUpdateTable = true;
      this.removeInProgress = false;
      this.selectedRows = [];
      this.gridHeaderMessage = this.initialHeaderMessage;
    }
  }
}
