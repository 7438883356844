import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ReusableTableComponent } from '../reusable-table/reusable-table.component';
import { Column } from '../../models/column.model';
import { DrawingCloneService } from '../../services/drawing-clone.service';
import { catchError, forkJoin, map, of, take } from 'rxjs';
import { ButtonComponent } from '../button/button.component';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { ErrorOverlayType } from '../../helpers/error-overlay.enum.';

@Component({
  selector: 'app-remove-collection',
  standalone: true,
  imports: [CommonModule, ReusableTableComponent, ButtonComponent],
  templateUrl: './remove-collection.component.html',
  styleUrl: './remove-collection.component.scss',
})
export class RemoveCollectionComponent implements OnDestroy {
  @ViewChild('createdAtTemplate') createdAtTemplate: TemplateRef<any>;
  @ViewChild('lastModifiedAtTemplate') lastModifiedAtTemplate: TemplateRef<any>;
  @ViewChild('createdByTemplate') createdByTemplate: TemplateRef<any>;

  completedCount: number = 0;
  selectedRows: any[] = [];
  columns: Column[];
  filterFields: string[];
  collections: any[];
  cancelText: string = 'Cancel';
  removeText: string = 'Remove';
  removeInProgress: boolean = false;
  @Output() collectionsRemoved = new EventEmitter<void>();

  constructor(private modalService: BsModalService, private drawingCloneService: DrawingCloneService, private errorHandlingService: ErrorHandlingService) {}

  ngOnDestroy(): void {
    this.errorHandlingService.hideOverlay();  
  }

  ngOnInit() {
    this.filterFields = [];
    this.collections.forEach((collection) => {
      this.selectedRows.push(collection);
    });
  }

  removeCollections() {
    this.removeInProgress = true;

    const observables = this.selectedRows.map((collection) =>
      this.drawingCloneService.removeCollection(collection.collectionGuid).pipe(
        take(1),
        map((response) => {
          this.completedCount++;
          return response;
        }),
        catchError((error) => {
          this.errorHandlingService.handleError(error);
          return of(null);
        })
      )
    );

    forkJoin(observables).subscribe(() => {
      this.collections = this.collections.filter((row) => !this.selectedRows.includes(row));
      this.selectedRows = [];
      this.removeInProgress = false;
      this.completedCount = 0;
      this.collectionsRemoved.emit();

      if (this.collections.length === 0) {
        this.modalService.hide();
      }
    });
  }

  ngAfterViewInit(): void {
    this.columns = [
      { header: 'Collection Name', key: 'title' },
      { header: 'Created On', key: 'createdOn', template: this.createdAtTemplate },
      { header: 'Created By', key: 'createdByUser', template: this.createdByTemplate },
      { header: 'Last Modified', key: 'modifiedOn', template: this.lastModifiedAtTemplate },
    ];
  }

  onClose(): void {
    this.modalService.hide();
  }
}
