<div class="users-page" *ngIf="isAdmin; else notAdmin">
  <h1>Users</h1>
  <app-role-switch *ngIf="isSuperAdmin" class="role-switch"></app-role-switch>
  <br />
  <app-reusable-table
    [headerTemplate]="headerTemplate"
    [columns]="columns"
    [data]="users$ | async"
    [searchEnabled]="true"
    [sortEnabled]="true"
    [paginationEnabled]="true"
    [isLoading]="isLoading"
    [filterFields]="filterFields"
    [tableSize]="'medium'"
    [(selectedRows)]="selectedRows"
  >
  </app-reusable-table>
</div>

<ng-template #notAdmin>
  <ng-container *ngIf="redirect()"></ng-container>
</ng-template>

<!-- Templates Section -->
<ng-template #headerTemplate>
  <div class="users-page-header">
    <span class="selected-users">{{ selectedRows.length }} users selected</span>
    <app-button
      [buttonClass]="'btn-primary add-user-btn'"
      [buttonText]="addNewUserText"
      [buttonEnabled]="!isLoading"
      (clickEvent)="openAddUserDialog()"
    />
    <app-button
      [buttonClass]="'btn-danger'"
      [buttonText]="removeAccessText"
      [buttonEnabled]="selectedRows.length > 0"
      (clickEvent)="openRemoveUserAccessDialog()"
    />
  </div>
</ng-template>

<ng-template #collectionsAssignedTemplate let-rowData="rowData">
  <div
    class="collection-icon"
    style="cursor: pointer; background: none; border: none; display: inline-flex; align-items: center"
    (click)="openDialog(rowData)"
  >
    <i class="modus-icons notranslate text-primary" aria-hidden="true" style="margin-right: 5px"> master_data </i>
  </div>
</ng-template>
