import { ActionReducer } from '@ngrx/store';
import { AuthState } from '../states/auth.state';
import { authStateReducer } from '../reducers/auth.reducer';

export interface AppStore {
  auth: ActionReducer<AuthState>;
}

export const appStore: AppStore = {
  auth: authStateReducer,
};
