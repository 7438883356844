<div class="remove-access-modal">
  <div class="modal-header">
    <h3>Remove collections</h3>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-warning text-dark fade show" role="alert">
      <i class="modus-icons mr-1" aria-hidden="true">warning</i>
      <div>Removing a collection will permanently delete the collection and all users will lose access to it.</div>
    </div>
    <br />
  </div>
  <app-reusable-table
    [headerTemplate]="headerTemplateRemoveCollection"
    [columns]="columns"
    [data]="collections"
    [searchEnabled]="true"
    [sortEnabled]="true"
    [isLoading]="false"
    [paginationEnabled]="true"
    [(selectedRows)]="selectedRows"
    [filterFields]="filterFields"
    [tableSize]="'small'"
  >
  </app-reusable-table>
  <div class="modal-footer">
    <app-button [buttonClass]="'btn-secondary'" [buttonText]="cancelText" (clickEvent)="onClose()" />
    <app-button
      [buttonClass]="'btn-danger'"
      [buttonText]="removeText"
      [buttonEnabled]="selectedRows.length > 0"
      [spinnerEnabled]="true"
      [inProgress]="removeInProgress"
      (clickEvent)="removeCollections()"
    />
  </div>
</div>
<ng-template #headerTemplateRemoveCollection>
  <span>{{ selectedRows.length }} collections selected</span>
</ng-template>
