import { createAction, props } from '@ngrx/store';
import { UserInfo } from '../../models/user.info';
import { AuthToken } from '../../models/auth.token';

export const login = createAction('[Auth] Login');

export const refreshToken = createAction('[Auth] Refresh Token', props<{ refreshToken: string }>());

export const setBearerToken = createAction('[Auth] Set Bearer Token', props<{ token: AuthToken }>());

export const setUserInfo = createAction('[Auth] Set User Info', props<{ userInfo: UserInfo }>());

export const setSuperAdminSwitch = createAction('[Auth] Set Super Admin Switch', props<{ superAdminSwitch: boolean }>());

export const logout = createAction('[Auth] Logout');