<div class="remove-access-modal">
  <div class="modal-header">
    <h3>Remove user access to collections</h3>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-reusable-table
      [headerTemplate]="headerTemplateRemoveAccess"
      [columns]="columns"
      [data]="tableData$ | async"
      [searchEnabled]="true"
      [sortEnabled]="true"
      [paginationEnabled]="true"
      [isLoading]="isLoading"
      (selectedRowsChange)="selectionChanged($event)"
      [(selectedRows)]="selectedRows"
      [filterFields]="filterFields"
      [tableSize]="'small'"
    >
    </app-reusable-table>
  </div>
  <div class="modal-footer">
    <app-button [buttonClass]="'btn-secondary'" [buttonText]="cancelText" (clickEvent)="onClose()"></app-button>
    <app-button
      [buttonClass]="'btn-danger'"
      [spinnerEnabled]="true"
      [buttonEnabled]="selectedRows.length > 0"
      [spinnerEnabled]="true"
      [inProgress]="removeInProgress"
      [buttonText]="removeText"
      (clickEvent)="removePermissions()"
    ></app-button>
  </div>
</div>

<!--Templates section-->
<ng-template #headerTemplateRemoveAccess>
  <span class="remove-access-grid-info">{{ gridHeaderMessage }}</span>
</ng-template>
<ng-template #createdAtTemplate let-rowData="rowData">
  <div>{{ rowData.createdOnEpoch * 1000 | date : 'medium' }}</div>
</ng-template>
<ng-template #lastModifiedAtTemplate let-rowData="rowData">
  <div>{{ rowData.modifiedOnEpoch * 1000 | date : 'medium' }}</div>
</ng-template>
<ng-template #emailTemplate let-rowData="rowData">
  <div>{{ rowData.permission.userName }}</div>
</ng-template>
<ng-template #roleTemplate let-rowData="rowData">
  <div class="chip chip-outline chip-filter">
    <div class="chip-text">{{ rowData.permission.permissionLevel }}</div>
  </div>
</ng-template>
