// app.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { AuthState } from '../states/auth.state';
import { logout, setBearerToken, setSuperAdminSwitch, setUserInfo } from '../actions/auth.actions';

export const initialAuthState: AuthState = {
  token: null,
  userInfo: null,
  superAdminSwitch: false,
};

export const authStateReducer = createReducer(
  initialAuthState,
  on(setBearerToken, (state, { token }) => ({
    ...state,
    token,
  })),
  on(setUserInfo, (state, { userInfo }) => ({
    ...state,
    userInfo,
  })),
  on(setSuperAdminSwitch, (state, { superAdminSwitch }) => ({
    ...state,
    superAdminSwitch,
  })),
  on(logout, (state) => ({
    ...state,
    userInfo: null,
    token: null,
  }))
);
