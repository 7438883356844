<div class="modus-layout">
  <nav class="modus-header navbar navbar-expand-lg" aria-label="Main Navigation">
    <button class="btn btn-lg btn-icon-only p-2 menu-btn" data-modus-item="menu-btn" data-toggle="#" type="button">
      <i class="modus-icons notranslate menu-icon" aria-hidden="true">menu</i>
    </button>
    <div class="navbar-brand mr-auto ml-3">
      <img
        src="https://modus-bootstrap.trimble.com/img/trimble-logo.svg"
        width="107"
        height="25"
        class="img-fluid d-none d-sm-block"
        alt="home"
      />
    </div>
    <div class="ml-auto d-flex align-items-center">
      <div class="dropdown">
        <button class="btn btn-lg dropdown-toggle arrow-down" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="modus-icons notranslate user-icon" aria-hidden="true">person</i>
          <span class="username">{{ username }}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="redirectToUserInfo()">User Info</a>
          <a class="dropdown-item" (click)="logout()">Log out</a>
        </div>
      </div>
    </div>
  </nav>
  <div class="modus-body sidebar-open" data-modus-item="body">
    <nav class="modus-sidebar nav flex-column" aria-label="Sidebar Navigation">
      <ul>
        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active">
            <span class="left-nav-icon"><i class="modus-icons notranslate" aria-hidden="true">home</i></span>
            Home
          </a>
        </li>
        <li *ngIf="userRole === 'Admin'">
          <a class="nav-link" routerLink="/users" routerLinkActive="active">
            <span class="left-nav-icon"><i class="modus-icons notranslate" aria-hidden="true">person</i></span>
            Users
          </a>
        </li>
        <li>
          <a class="nav-link" routerLink="/collections" routerLinkActive="active">
            <span class="left-nav-icon"><i class="modus-icons notranslate" aria-hidden="true">master_data</i></span>
            Collections
          </a>
        </li>
      </ul>
    </nav>

    <div class="modus-content-rows">
      <router-outlet *ngIf="loggedIn"></router-outlet>
    </div>
  </div>
</div>
