import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Role } from '../../helpers/role.enum';

import { DrawingCloneService } from '../../services/drawing-clone.service';
import { Observable, catchError, finalize, first, map, of, tap } from 'rxjs';
import { CollectionEntity, CollectionEntityWithRole } from '../../models/collection.entity';

import { ButtonComponent } from '../button/button.component';
import { Store } from '@ngrx/store';
import { UserInfo } from '../../models/user.info';
import { AppState } from '../../ngrx/states/app.state';
import { selectUserInfoObject } from '../../ngrx/selectors/auth.selector';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ReusableTableComponent } from '../reusable-table/reusable-table.component';
import { RoleDropdownComponent } from '../role-dropdown/role-dropdown.component';
import { Column } from '../../models/column.model';
import { TooltipModule } from 'primeng/tooltip';
import { Permission } from '../../models/permission';
import { environment } from '../../environments/environment';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { ErrorOverlayType } from '../../helpers/error-overlay.enum.';

@Component({
  selector: 'app-add-user',
  standalone: true,
  imports: [FormsModule, CommonModule, ReusableTableComponent, RoleDropdownComponent, ButtonComponent, TooltipModule],
  templateUrl: './add-user.component.html',
  styleUrl: './add-user.component.scss',
})
export class AddUserComponent implements OnInit, AfterViewInit, OnDestroy {
  atcUrl = environment.atcUrl;
  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  userEmail: string = '';
  adminEmail: string = '';
  emailChips: any[] = [];
  emailValid: boolean = false;
  isLoading: boolean = true;
  addUsersEnabled: boolean = false;
  userInfo$: Observable<UserInfo>;
  selectedRows: any[] = [];
  collections$: Observable<CollectionEntity[]>;
  tableData$: Observable<CollectionEntityWithRole[]>;
  columns: Column[];
  filterFields: string[];
  selectedCollectionsCount: number = 0;
  addUserText: string = 'Add user';
  addUserInProgress: boolean = false;
  confirmText: string = 'Save';
  assignInProgress: boolean = false;
  cancelText: string = 'Cancel';

  @ViewChild('roleTemplate') roleTemplate: TemplateRef<any>;
  @ViewChild('headerTooltipTemplate') headerTooltipTemplate: TemplateRef<any>;

  @Output() usersAdded = new EventEmitter<any>();

  constructor(
    private drawingCloneService: DrawingCloneService,
    private errorHandlingService: ErrorHandlingService,
    private store: Store<AppState>,
    private modalService: BsModalService
  ) {
    this.store.select(selectUserInfoObject).subscribe((userInfo) => {
      this.adminEmail = userInfo.email;
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.tableData$ = this.drawingCloneService.getUserCollections().pipe(
      map((collections: CollectionEntity[]) => collections.map((collection) => ({ ...collection, role: Role.Viewer }))),
      tap(() => (this.isLoading = false)),
      catchError((error) => {
        this.isLoading = false;
        this.errorHandlingService.handleError(error, ErrorOverlayType.Toast, this);
        return of([]);
      })
    );

    this.filterFields = ['title', 'role'];
  }

  ngAfterViewInit(): void {
    this.columns = [
      { header: 'Collection Name', key: 'title' },
      { header: 'Role', key: 'role', template: this.roleTemplate, headerTemplate: this.headerTooltipTemplate },
    ];
  }

  ngOnDestroy(): void {
    this.errorHandlingService.hideOverlay();
  }

  isValidEmail(): boolean {
    const alreadyExists = this.emailChips.some((chip) => chip.email === this.userEmail.trim());
    return this.emailRegex.test(this.userEmail.trim()) && !alreadyExists;
  }

  showExternalEditorWarning(): boolean {
    return this.emailChips.some((chip) => chip.external) && this.selectedRows.some((row) => row.role === Role.Editor);
  }

  enableAddUsers(): boolean {
    return (
      this.emailChips.length > 0 &&
      this.selectedRows.length > 0 &&
      this.emailChips.some((chip) => chip.validUser === true)
    );
  }

  getInvalidUsersCount(): number {
    return this.emailChips.filter((chip) => !chip.validUser).length;
  }

  addUserChip() {
    this.addUserInProgress = true;
    if (this.userEmail && this.adminEmail) {
      this.isLoading = true;
      this.drawingCloneService
        .getUserInfo(this.userEmail)
        .pipe(
          first(),
          catchError(() => {
            const email = this.userEmail.trim();
            this.emailChips.push({ displayName: email, email: email, external: false, validUser: false });
            return of(null);
          }),
          finalize(() => {
            this.userEmail = '';
            this.emailValid = false;
            this.isLoading = false;
            this.addUserInProgress = false;
          })
        )
        .subscribe((userInfo) => {
          if (userInfo) {
            const adminDomain = this.extractDomain(this.adminEmail);
            const userDomain = this.extractDomain(this.userEmail);

            this.emailChips.push({
              displayName: userInfo.displayName,
              email: this.userEmail.trim(),
              external: adminDomain !== userDomain,
              validUser: true,
            });
          }
        });
    }
  }

  removeEmailChip(emailChipToRemove: string): void {
    this.emailChips = this.emailChips.filter((chip) => chip.email !== emailChipToRemove);
  }

  onClose(): void {
    this.modalService.hide();
  }

  confirmChanges(): void {
    this.assignInProgress = true;
    const permissions: Permission[] = [];
    this.selectedRows.forEach((row: CollectionEntityWithRole) => {
      // Push permission objects
      permissions.push({
        collectionId: row.collectionGuid,
        permissionLevel: row.role,
        permissionCreation: new Date(),
        userId: null,
        permissionExpiration: null,
        permissionDetails: null,
      });
    });

    this.drawingCloneService
      .assignPermissions(
        this.emailChips.filter((user) => user.validUser).map((chip) => chip.email),
        permissions
      )
      .pipe(first())
      .subscribe({
        next: () => {
          this.usersAdded.emit();
          this.modalService.hide();
        },
        error: () => {
          // TODO: Display error message
        },
        complete: () => {
          this.assignInProgress = false;
        },
      });
  }

  extractDomain(email: string): string {
    return email.split('@')[1];
  }

  selectionChanged($event) {
    this.selectedRows = $event;
    this.selectedCollectionsCount = this.selectedRows.length;
  }
}
