<div class="dropdown">
  <button
    [class]="btnClass"
    [ngClass]="{'placeholder-text': showPlaceHolderText()}"
    type="button"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    {{ role }}
  </button>
  <div class="dropdown-menu">
    <button *ngFor="let roleOption of roles" class="dropdown-item" type="button" (click)="onChange(roleOption)">
      {{ roleOption }}
    </button>
  </div>
</div>
