import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textOverflow',
  standalone: true
})
export class TextOverflowPipe implements PipeTransform {
  transform(value: string, limit: number): string {
    if (!value){
        return '';
    }
    return value.length > limit ? value.substring(0, limit - 3) + '...' : value;
  }
}
