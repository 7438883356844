import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { finalize, first, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Output() clickEvent : EventEmitter<void> = new EventEmitter<void>();
  @Input() buttonEnabled : boolean = true;
  @Input() buttonText : string = 'Submit';
  @Input() buttonClass : string = 'btn-primary';
  @Input() spinnerEnabled : boolean = false;
  @Input() inProgress : boolean = false;

  click() {
    this.clickEvent.emit();
  }
}
