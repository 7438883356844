import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, skip, switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../ngrx/states/app.state';
import { refreshToken } from '../ngrx/actions/auth.actions';
import { AuthToken } from '../models/auth.token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Ignore requests to IdentityService and requests that already have an Authorization header
    if (request.url.includes(environment.authenticationURI) || request.headers.has('Authorization')) {
      return next.handle(request);
    }

    let waitForToken = 0; // if the token needs to be refreshed, we'll skip the first value emitted by the store
    const bearerToken: AuthToken = JSON.parse(sessionStorage.getItem('bearerToken'));

    if (bearerToken) {
      if (Math.round(Date.now() / 1000) > bearerToken.expiresAt - 360) {
        this.store.dispatch(refreshToken({ refreshToken: bearerToken.refreshToken })); // refresh token if it's about to expire
        waitForToken = 1;
      }

      return this.store.select('auth', 'token').pipe(
        filter((token) => !!token),
        skip(waitForToken),
        switchMap((token) => {
          const clonedRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token.accessToken}`, // add the bearer token to the request
            },
          });
          return next.handle(clonedRequest);
        })
      );
    }

    throw new Error('No bearer token found');
  }
}
