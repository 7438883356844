<div class="container">
  <div class="header-top">
    <h3>Collections for user: {{ userName }}</h3>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="showExternalEditorWarning()" class="warning-external">
    <div class="alert alert-warning text-dark fade show" role="alert">
      <i class="modus-icons mr-1" aria-hidden="true">warning</i>
      <div>
        Be aware that you are giving editor access to a user with a different domain email address to your own
        (&#64;{{ extractDomain(adminEmail) }}).
      </div>
    </div>
  </div>
  <app-reusable-table
    class="reusable-table"
    [columns]="columns"
    [data]="tableData"
    [searchEnabled]="true"
    [sortEnabled]="true"
    [paginationEnabled]="true"
    [isLoading]="isLoading"
    tableSize="small"
    [headerTemplate]="headerTemplate"
    [(selectedRows)]="selectedRows"
    [filterFields]="filterFields"
    [rowClassFunction]="isPendingRow"
  ></app-reusable-table>
  <div class="button-container">
    <app-button
      [buttonClass]="'btn-danger remove-access-button'"
      [buttonText]="removeAccessText"
      [buttonEnabled]="selectedRows.length > 0"
      [spinnerEnabled]="true"
      [inProgress]="removeAccessInProgress"
      (clickEvent)="removePermissionsFromSelectedRows()"
    />
    <div class="modal-footer">
      <app-button [buttonClass]="'btn-secondary'" [buttonText]="cancelText" (clickEvent)="onClose()" />
      <app-button
        [buttonClass]="'btn-primary'"
        [buttonText]="confirmText"
        [buttonEnabled]="shouldEnableSaveButton()"
        [spinnerEnabled]="true"
        [inProgress]="confirmInProgress"
        (clickEvent)="confirmChanges()"
      />
    </div>
  </div>
</div>

<!--Templates-->
<ng-template #headerTemplate>
  <div class="header-row">
    <span class="selected-collections-info">{{ selectedRows.length }} collections selected</span>
  </div>
</ng-template>

<ng-template #roleTemplate let-rowData="rowData">
  <app-role-dropdown [role]="rowData.permissionLevel" (roleChange)="onRoleChange($event, rowData)"></app-role-dropdown>
</ng-template>

<ng-template #dateFormatTemplate let-rowData="rowData">
  <span>{{ rowData.createdOn | date : 'dd/MM/yyyy HH:mm' }}</span>
</ng-template>
