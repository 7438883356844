import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { DrawingCloneService } from '../../services/drawing-clone.service';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { ReusableTableComponent } from '../reusable-table/reusable-table.component';
import { RoleDropdownComponent } from '../role-dropdown/role-dropdown.component';
import { AccessDropdownComponent } from '../access-dropdown/access-dropdown.component';
import { AsyncPipe, CommonModule, DatePipe, NgFor, NgIf } from '@angular/common';
import { Column } from '../../models/column.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/states/app.state';
import { RemoveCollectionComponent } from '../remove-collection/remove-collection.component';
import { UsersManagerComponent } from '../users-manager/users-manager.component';
import { Role } from '../../helpers/role.enum';
import { ActivatedRoute } from '@angular/router';
import { ButtonComponent } from '../button/button.component';
import { TooltipModule } from 'primeng/tooltip';
import { ExternalTextPipe } from '../../helpers/external-text.pipe';

@Component({
  selector: 'app-collections',
  standalone: true,
  imports: [ReusableTableComponent, RoleDropdownComponent, AccessDropdownComponent, ButtonComponent, NgIf, NgFor, AsyncPipe, CommonModule, TooltipModule],
  templateUrl: './collections.component.html',
  styleUrl: './collections.component.scss',
  providers: [DrawingCloneService, DatePipe, ExternalTextPipe],
})
export class CollectionsComponent implements AfterViewInit, OnInit {
  bsModalRef?: BsModalRef;
  columns: Column[];
  filterFields: string[];
  isLoading = true;
  isAdmin = false;
  selectedRows: any[] = [];
  collections$: Observable<any[]>;
  peselectedCollectionGuid: string;
  manageUsersText: string = 'Manage users';
  removeCollectionText: string = 'Remove collection';
  changeAccessText: string = 'Save changes';
  userEmail: string;
  organizationId: string;
  changes: Map<string, string> = new Map<string, string>();

  isRowDisabled = (rowData: any): boolean => !this.isAdmin && rowData.role !== "Editor" || rowData.linkedCompany !== this.organizationId;

  @ViewChild('usersAssignedTemplate') usersAssignedTemplate: TemplateRef<any>;
  @ViewChild('createdDateFormatTemplate') createdDateTemplate: TemplateRef<any>;
  @ViewChild('modifiedDateFormatTemplate') modifiedDateTemplate: TemplateRef<any>;
  @ViewChild('accessTemplate') accessTemplate: TemplateRef<any>;
  @ViewChild('headerTooltipTemplate') headerTooltipTemplate: TemplateRef<any>;

  constructor(
    private store: Store<AppState>,
    private drawingCloneService: DrawingCloneService,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private externalTextPipe: ExternalTextPipe,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['collectionId']) {
        this.peselectedCollectionGuid = params['collectionId'];
      }
    });

    this.loadData();
    this.filterFields = ['title', 'createdOn', 'modifiedOn', 'role', 'access', 'usersAssigned', 'createdByUser', 'modifiedByUser'];

    this.store.select('auth', 'userInfo').subscribe((user) => {
      if (user) {
        this.userEmail = user.email;
      }
    });
  }

  private getUserCollectionsEndpoint(): Observable<any[]> {
    return this.isAdmin ? this.drawingCloneService.getUserCollections(true) : this.drawingCloneService.getAssignedCollections();
  }

  onSelectedRowsChange(rows: any[]) {
    this.selectedRows = rows;
  }

  ngAfterViewInit() {
    this.columns = [
      { header: 'Collection name', key: 'title' },
      { header: 'Created on', key: 'createdOn', template: this.createdDateTemplate},
      { header: 'Last Modified', key: 'modifiedOn', template: this.modifiedDateTemplate},
      { header: 'Role', key: 'role' },
      { header: 'Access', key: 'access', template: this.accessTemplate, headerTemplate: this.headerTooltipTemplate },
      { header: 'Users assigned', key: 'usersAssigned', template: this.usersAssignedTemplate },
    ];

    this.cdr.detectChanges();
  }

  openUsersManagerDialog(row: any = undefined): void {
    if ((!this.isAdmin && row && row.role !== Role.Editor) || (row && row.linkedCompany !== this.organizationId)) {
      return;
    }
    const initialState: ModalOptions = {
      initialState: {
        selectedCollections: row ? [row] :
        this.selectedRows.filter(collection => (this.isAdmin || collection.role === Role.Editor) && collection.linkedCompany === this.organizationId),
      },
    };
    this.bsModalRef = this.modalService.show(UsersManagerComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.changesMade.subscribe(() => {
      this.selectedRows = [];
      this.isLoading = true;
      this.loadData();
    });
  }

  openRemoveCollectionDialog(): void {
    const initialState: ModalOptions = { initialState: { title: 'Remove Collection', collections: this.selectedRows}};
    this.bsModalRef = this.modalService.show(RemoveCollectionComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';

    this.bsModalRef.content.collectionsRemoved.subscribe(() => {
      this.selectedRows = [];
      this.isLoading = true;
      this.loadData();
    });
  }

  onAccessChange(row: any, event: any) {
    if(row.initialAccess === event) {
      this.changes.delete(row.collectionGuid);
    }
    else{
      this.changes.set(row.collectionGuid, event);
    }
    row.access = event;
  }

  changeCollectionsAccess() {
    const collectionsAccess = Array.from(this.changes.entries()).map(([collectionGuid, access]) => ({
      collectionGuid,
      access
    }));
    
    this.isLoading = true;
    this.drawingCloneService.changeCollectionsAccess(collectionsAccess).pipe(
      tap(() => this.loadData()),
      catchError((error) => {
        this.isLoading = false;
        return of([]);
      })
    ).subscribe();
  }

  loadData() {
    this.changes.clear();
    this.store.select('auth', 'userInfo').subscribe((user) => {
      if (user) {
        this.isAdmin = user.organizationRole === 'Admin';
        this.organizationId = user.organizationId;
        this.collections$ = this.getUserCollectionsEndpoint().pipe(
          tap(() => (this.isLoading = false)),
          catchError((error) => {
            this.isLoading = false;
            return of([]);
          }),
          map((response) => {
            return response.map((collection) => {
              const accessValue = this.getAccessValue(collection, user);
              return {
                collectionGuid: collection.collectionGuid,
                createdByUser: collection.createdByUser,
                modifiedByUser: collection.modifiedByUser,
                title: collection.title,
                createdOn: new Date(collection.createdOnEpoch * 1000),
                modifiedOn: new Date(collection.modifiedOnEpoch * 1000),
                role: !!collection.permission ? this.externalTextPipe.transform(collection.permission?.permissionLevel, collection.linkedCompany, user.organizationId) : 'N/A',
                access: accessValue,
                initialAccess: accessValue,
                linkedCompany: collection.linkedCompany
              };
            });
          }),
          tap((collections) => {
            if(this.peselectedCollectionGuid){
              const selectedCollection = collections.find(collection => collection.collectionGuid === this.peselectedCollectionGuid);
              if(selectedCollection){
                this.openUsersManagerDialog(selectedCollection);
              }
            }
          })
        );
      }
    });
  }

  hasAccessToSelectedRows(){
      return this.isAdmin || this.selectedRows.filter(row => row.role === Role.Editor).length > 0;
  }

  isPendingRow(rowData: any): string {
    return rowData.access !== rowData.initialAccess ? 'pending-changes' : '';
  }

  private getAccessValue(collection: any, user: any): string {
    return collection.linkedCompany === user.organizationId ? collection.access : "Invitation";
  }
  
}
