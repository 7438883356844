import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  drawingCloneServiceUrl: 'https://cloud.dev.api.trimblecloud.com/tekla/cloneservice/v4',
  authenticationURI: 'https://stage.id.trimblecloud.com/oauth',
  clientId: '91765ef9-d330-4ca5-9938-ccf129aa96fe',
  clientSecret: '2b9bc48b853f400994d5e8fb337d694e',
  redirectUrl: '',
  atcUrl: 'https://account.stg.tekla.com'
};
