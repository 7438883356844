<div *ngIf="showError && overlayType === 'Alert'" class="error-alert fade-in" #errorAlert>
  <div class="alert alert-danger d-flex align-items-center" role="alert">
    <i class="modus-icons">warning</i>
    <strong class="error-message">
      {{ errorMessage }}
    </strong>
    <button *ngIf="canRetry" type="button" class="btn btn-danger retry-button" (click)="onRetry()">Retry</button>
    <button type="button" class="close" aria-label="Close" (click)="onClose(errorAlert)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div *ngIf="showError && overlayType === 'Toast'" class="toast toast-danger show align-items-center mt-2 fade-in" #errorToast>
  <div class="toast-header pe-2 align-items-start">
    <i class="modus-icons">warning</i>
    <strong class="error-message">
      {{ errorMessage }}
    </strong>
    <button *ngIf="canRetry" type="button" class="btn btn-danger retry-button" (click)="onRetry()">Retry</button>
    <button type="button" class="close" aria-label="Close" (click)="onClose(errorToast)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>