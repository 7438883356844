import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { CommonModule, DatePipe } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DrawingCloneService } from '../../services/drawing-clone.service';
import { catchError, finalize, first, firstValueFrom, of } from 'rxjs';
import { Column } from '../../models/column.model';
import { RoleDropdownComponent } from '../role-dropdown/role-dropdown.component';
import { ReusableTableComponent } from '../reusable-table/reusable-table.component';
import { UserCollectionEntity } from '../../models/user-collection.entity';
import { CollectionEntityWithPermission } from '../../models/collection.entity';
import { Permission } from '../../models/permission';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/states/app.state';
import { selectUserInfoObject } from '../../ngrx/selectors/auth.selector';

@Component({
  selector: 'app-user-collections',
  standalone: true,
  imports: [ButtonComponent, CommonModule, RoleDropdownComponent, ReusableTableComponent],
  providers: [DatePipe],
  templateUrl: './user-collections.component.html',
  styleUrl: './user-collections.component.scss',
})
export class UserCollectionsComponent implements OnInit {
  public userId: string;
  public userMail: string;
  public userName: string;
  public adminEmail: string;
  private changes: Map<string, string> = new Map<string, string>();
  tableData: UserCollectionEntity[] = [];
  selectedRows: UserCollectionEntity[] = [];
  columns: Column[];
  filterFields: string[];
  isLoading = true;
  removeAccessText = 'Remove access';
  cancelText = 'Cancel';
  confirmText = 'Save';
  removeAccessInProgress = false;
  confirmInProgress = false;
  shouldUpdateTable: boolean = false;
  @Output() changesMade: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('roleTemplate') roleTemplate: TemplateRef<any>;
  @ViewChild(ReusableTableComponent) table: ReusableTableComponent;
  @ViewChild('dateFormatTemplate') dateTemplate: TemplateRef<any>;

  constructor(public dialogRef: BsModalRef, private drawingCloneService: DrawingCloneService, private store: Store<AppState>) {
    this.store.select(selectUserInfoObject).subscribe((userInfo) => {
      this.adminEmail = userInfo.email;
    });
    this.dialogRef.onHidden.subscribe(() => {
      if (this.shouldUpdateTable) {
        this.changesMade.emit();
      }
    });
  }

  async ngOnInit() {
    this.loadCollections();
  }

  loadCollections() {
    this.setEmptyTableData();
    this.isLoading = true;

    this.drawingCloneService.getUserCollectionsWithPermission(this.userId).subscribe({
      next: (collections) => {
        if (!collections.length) {
          this.setEmptyTableData();
        } else {
          this.setTableData(collections);
          this.filterFields = ['title', 'createdOn', 'modifiedOn', 'permissionLevel'];
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.error('An error occurred:', error);
        this.setEmptyTableData();
        this.isLoading = false;
      },
    });
  }

  private setTableData(collections: CollectionEntityWithPermission[]) {
    this.tableData = collections.map(({ collectionGuid, title, createdOnEpoch, modifiedOnEpoch, permission }) => {
      const permissionLevel = permission ? permission.permissionLevel : 'N/A';
      const createdOn = new Date(createdOnEpoch * 1000);
      const modifiedOn = new Date(modifiedOnEpoch * 1000);

      return {
        collectionGuid,
        title,
        createdOn,
        modifiedOn,
        permissionLevel,
        initialPermissionLevel: permissionLevel,
      };
    });
  }

  private setEmptyTableData() {
    this.tableData = [];
  }

  ngAfterViewInit(): void {
    this.columns = [
      { header: 'Collection Name', key: 'title' },
      { header: 'Created On', key: 'createdOn', template: this.dateTemplate },
      { header: 'Last Modified', key: 'modifiedOn', template: this.dateTemplate },
      { header: 'Role', key: 'permissionLevel', template: this.roleTemplate },
    ];
  }

  onRoleChange(event: any, rowData: any) {
    if (event !== rowData.initialPermissionLevel) {
      this.changes.set(rowData.collectionGuid, event);
      this.selectedRows = [...this.selectedRows, rowData];
      this.table.selectedRowsChange.emit(this.selectedRows);
    } else {
      this.changes.delete(rowData.collectionGuid);
      this.selectedRows = this.selectedRows.filter((row) => row.collectionGuid !== rowData.collectionGuid);
      this.table.selectedRowsChange.emit(this.selectedRows);
    }
    const index = this.tableData.findIndex((row) => row === rowData);
    this.tableData[index].permissionLevel = event;
  }

  selectionChanged(event: any) {
    this.selectedRows = event;
  }

  hasChanges() {
    return this.changes.size > 0;
  }

  onClose() {
    this.dialogRef.hide();
  }

  async confirmChanges() {
    this.confirmInProgress = true;

    const tasks = Array.from(this.changes.entries()).map(async ([collectionGuid, newPermissionLevel]) => {
      await this.updatePermissionAndReflectInTableData(collectionGuid, newPermissionLevel);
    });

    await Promise.all(tasks);

    this.confirmInProgress = false;
    this.changes.clear();
    this.shouldUpdateTable = true;
  }

  private async updatePermissionAndReflectInTableData(collectionGuid: string, newPermissionLevel: string) {
    try {
      const permission: Permission = {
        collectionId: collectionGuid,
        permissionLevel: newPermissionLevel,
        permissionCreation: new Date(),
        permissionDetails: null,
        userId: null,
        permissionExpiration: null,
      };

      await firstValueFrom(this.drawingCloneService.assignPermissions([this.userMail], [permission]));
      const entry = this.tableData.find((item) => item.collectionGuid === collectionGuid);
      if (entry) {
        entry.initialPermissionLevel = newPermissionLevel;
      }
    } catch (error) {
      console.error(`Failed to update permission for collection ${collectionGuid}:`, error);
    }
  }

  removePermissionsFromSelectedRows() {
    this.removeAccessInProgress = true;
    const collectionIds = this.selectedRows.map((row) => row.collectionGuid);

    this.drawingCloneService
      .removePermissions(collectionIds, this.userId)
      .pipe(
        first(),
        catchError((error) => {
          console.error('An error occurred:', error);
          return of(null);
        }),
        finalize(() => {
          this.loadCollections();

          this.selectedRows = [];
          this.changes.clear();
          this.removeAccessInProgress = false;
          this.shouldUpdateTable = true;
        })
      )
      .subscribe();
  }

  isPendingRow(rowData: any): string {
    return rowData.initialPermissionLevel !== rowData.permissionLevel ? 'pending-changes' : '';
  }

  showExternalEditorWarning(): boolean {
    const userDomain = this.extractDomain(this.userMail);
    const adminDomain = this.extractDomain(this.adminEmail);

    return this.tableData.some((row) =>
      row.permissionLevel === 'Editor' &&
      row.initialPermissionLevel !== 'Editor' &&
      userDomain !== adminDomain
    );
  }

  extractDomain(email: string): string {
    return email.split('@')[1];
  }

  shouldEnableSaveButton(): boolean {
    if (this.removeAccessInProgress) {
      return false;
    }
    return this.hasChanges();
  }
}
