import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from './ngrx/states/app.state';
import { login, logout } from './ngrx/actions/auth.actions';
import { environment } from './environments/environment';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  username: string;
  userRole: string;
  loggedIn: boolean;

  constructor(private store: Store<AppState>) {
    this.store.select('auth').subscribe((auth) => {
      if (!auth.token) {
        this.store.dispatch(login());
      } else {
        if (auth.userInfo) {
          const familyName = auth.userInfo.familyName;
          const givenName = auth.userInfo.givenName;
          this.username = familyName + ' ' + givenName;
          this.userRole = auth.userInfo.organizationRole;
          this.loggedIn = true;
        }
      }
    });
  }

  logout() {
    this.store.dispatch(logout());
  }

  redirectToUserInfo(): void {
    window.location.href = environment.atcUrl + '/account/';
  }
}
