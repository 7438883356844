import { Routes, mapToCanActivate } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { UsersComponent } from './components/users/users.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { AuthGuard } from './services/auth.guard';

export const routes: Routes = [
  {
    path: '',
    canActivateChild: mapToCanActivate([AuthGuard]),
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'users', component: UsersComponent },
      { path: 'collections', component: CollectionsComponent }
    ],
  },
];
