import { CommonModule, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { Access } from '../../helpers/access.enum';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/states/app.state';
import { TextOverflowPipe } from '../../helpers/text-overflow.pipe';

@Component({
  selector: 'app-access-dropdown',
  standalone: true,
  imports: [DropdownModule, FormsModule, NgFor, CommonModule, TextOverflowPipe],
  templateUrl: './access-dropdown.component.html',
  styleUrl: './access-dropdown.component.scss',
})
export class AccessDropdownComponent implements OnInit {
  @Input() access: string;
  @Output() accessChange = new EventEmitter<string>();
  @Input() btnClass: string = 'btn btn-tertiary dropdown-toggle rounded-pill soft-blue';
  accessLevels: Access[];
  organizationName: string;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.accessLevels = Object.values(Access);
    this.store.select('auth', 'userInfo').subscribe((user) => {
      if (user) {
        this.organizationName = user.organizationName;
        if(user.organizationRole !== 'Admin'){
          this.accessLevels = this.accessLevels.filter((accessLevel) => accessLevel !== Access.Public);
        }
      }
    });
  }

  onChange(access: string) {
    this.access = access;
    this.accessChange.emit(access);
  }

  getAccessOptionText(access: string): string {
    if(access === Access.Organization){
      return this.organizationName + " (Org)";
    }
    return access;
  }
}
