import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorOverlayType as ErrorOverlayType } from '../helpers/error-overlay.enum.';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  private callingComponent: any;
  private showErrorSubject = new BehaviorSubject<{message: string, overlay: ErrorOverlayType, canRetry: boolean}>(null);
  showError$ = this.showErrorSubject.asObservable();

  constructor() {}

  handleError(error: any, overlayType: ErrorOverlayType = ErrorOverlayType.Toast, componentInstance?: any) {
    let errorMessage = 'Unkown error. Please try again later.';
    this.callingComponent = componentInstance;
    switch (error.status) {
      case 0:
        errorMessage = 'No connection to the service. Please check your network connection.';
        break;
      case 400:
        errorMessage = 'The server could not process the request.';
        break;
      case 401:
        errorMessage = 'You are not authorized to perform this action.';
        break;
      case 403:
        errorMessage = 'Forbidden. You do not have permissions to perform this action.';
        break;
      case 404:
        errorMessage = 'The requested resource could not be found.';
        break;
      case 500:
        errorMessage = 'Internal Server Error. Please try again later.';
        break;
      case 503:
        errorMessage = 'Service unavailable. Please try again later.';
        break;
    }
    
    this.showErrorSubject.next({message: errorMessage, overlay: overlayType, canRetry: !!componentInstance});
  }

  hideOverlay() {
    this.callingComponent = null;
    this.showErrorSubject.next(null);
  }

  refreshComponent() {
    if(this.callingComponent) {
      this.callingComponent.ngOnInit();
    }
  }
}
