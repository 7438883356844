<div class="add-user-modal">
  <div class="modal-header">
    <h3>Add users</h3>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="add-user-container">
      <div *ngIf="showExternalEditorWarning()" class="warning-external">
        <div class="alert alert-warning text-dark fade show" role="alert">
          <i class="modus-icons mr-1" aria-hidden="true">warning</i>
          <div>
            Be aware that you are giving editor access to a user with a different domain email address to your own
            (&#64;{{ extractDomain(adminEmail) }}).
          </div>
        </div>
      </div>
      <div *ngIf="getInvalidUsersCount() > 0" class="warning-external">
        <div class="alert alert-danger text-dark fade show" role="alert">
          <i class="modus-icons mr-1" aria-hidden="true">warning</i>
          <div *ngIf="getInvalidUsersCount() === 1; else multipleUserError">
            This user could not be added. The user must create a Tekla Online profile at
            <a href="{{ atcUrl }}">{{ atcUrl }}</a> before you add the user.
          </div>
          <ng-template #multipleUserError>
            <div>
              These users could not be added. The users must create a Tekla Online profile at
              <a href="{{ atcUrl }}">{{ atcUrl }}</a> before you add them.
            </div>
          </ng-template>
        </div>
      </div>
      <div class="search-elements">
        <input
          type="text"
          class="form-control"
          id="userEmail"
          placeholder="Add user email address"
          [(ngModel)]="userEmail"
          (input)="emailValid = isValidEmail()"
          [disabled]="isLoading"
        />
        <app-button 
        [buttonClass]="'btn-primary'" 
        [spinnerEnabled]="true" 
        [buttonEnabled]="emailValid" 
        [buttonText]="addUserText" 
        [inProgress]="addUserInProgress" 
        (clickEvent)="addUserChip()"/>
      </div>
    </div>
    <div class="chips-container mt-2">
      <div
        *ngFor="let emailChip of emailChips"
        class="chip chip-outline"
        [ngClass]="emailChip.validUser ? (emailChip.external ? 'chip-external' : 'chip-internal') : 'chip-error'"
      >
        {{ emailChip.displayName }}
        <div (click)="removeEmailChip(emailChip.email)" class="chip-delete-right">
          <i class="modus-icons notranslate" aria-hidden="true">cancel_circle</i>
        </div>
      </div>
    </div>
  </div>

  <!-- Section 2: Grid for User Collection -->
  <div class="modal-body">
    <p>The following collections will be assigned to all listed users.</p>
    <app-reusable-table
      [headerTemplate]="headerTemplateAddUsers"
      [columns]="columns"
      [data]="tableData$ | async"
      [searchEnabled]="true"
      [sortEnabled]="true"
      [paginationEnabled]="true"
      [isLoading]="isLoading"
      (selectedRowsChange)="selectionChanged($event)"
      [filterFields]="filterFields"
      [tableSize]="'small'"
    >
    </app-reusable-table>
  </div>

  <div class="modal-footer">
    <app-button 
    [buttonClass]="'btn-secondary'"  
    [buttonText]="cancelText" 
    (clickEvent)="onClose()"/>
    <app-button 
    [buttonClass]="'btn-primary'" 
    [spinnerEnabled]="true" 
    [buttonEnabled]="enableAddUsers()" 
    [buttonText]="confirmText" 
    [inProgress]="assignInProgress" 
    (clickEvent)="confirmChanges()"/>
  </div>
</div>

<!--Templates section-->
<ng-template #headerTemplateAddUsers>
  <span class="add-user-grid-info">
    {{ selectedCollectionsCount > 0 ? selectedCollectionsCount + ' collections selected' : 'Collections assigned' }}
  </span>
</ng-template>
<ng-template #roleTemplate let-rowData="rowData">
  <app-role-dropdown [role]="rowData.role" (roleChange)="rowData.role = $event"></app-role-dropdown>
</ng-template>

<ng-template #headerTooltipTemplate let-col>
  <div style="display: flex; align-items: center">
    {{ col.header }}
    <i
      [pTooltip]="tooltipContent"
      tooltipPosition="bottom"
      class="modus-icons"
      aria-hidden="true"
      style="margin-left: 5px; color: #0063a3"
      >info_outlined</i
    >
  </div>
</ng-template>

<ng-template #tooltipContent>
  <div class="flex align-items-center tooltip-content">
    <ul>
      <li>
        <strong>Editor:</strong> Can edit collection contents, such as adding drawings to libraries, moving drawings
        between libraries, change library names, etc.
      </li>
      <li>
        <strong>Viewer:</strong> Can only use Collections to create drawings from them. Can view collection contents,
        but cannot do any changes to them.
      </li>
    </ul>
  </div>
</ng-template>
