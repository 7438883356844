<div class="dropdown">
    <button
      [class]="btnClass"
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ getAccessOptionText(access) | textOverflow: 13 }}
    </button>
    <div class="dropdown-menu">
      <button *ngFor="let accessOption of accessLevels" class="dropdown-item" type="button" (click)="onChange(accessOption)">
        {{ getAccessOptionText(accessOption) }}
      </button>
    </div>
  </div>
  