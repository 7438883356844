import { CommonModule, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { Role } from '../../helpers/role.enum';

@Component({
  selector: 'app-role-dropdown',
  standalone: true,
  imports: [DropdownModule, FormsModule, NgFor, CommonModule],
  templateUrl: './role-dropdown.component.html',
  styleUrl: './role-dropdown.component.scss',
})
export class RoleDropdownComponent {
  @Input() role: string;
  @Output() roleChange = new EventEmitter<string>();
  @Input() btnClass: string = 'btn btn-tertiary dropdown-toggle rounded-pill soft-blue';
  roles = Object.values(Role);

  onChange(role: string) {
    this.role = role;
    this.roleChange.emit(role);
  }

  showPlaceHolderText() {
    return !this.roles.includes(this.role as Role);
  }
}
