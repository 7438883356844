import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { CommonModule } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DrawingCloneService } from '../../services/drawing-clone.service';
import { Column } from '../../models/column.model';
import { RoleDropdownComponent } from '../role-dropdown/role-dropdown.component';
import { ReusableTableComponent } from '../reusable-table/reusable-table.component';
import { UserCollectionRoleEntity } from '../../models/user-collection-role.entity';
import { FormsModule } from '@angular/forms';
import { Role } from '../../helpers/role.enum';
import { TooltipModule } from 'primeng/tooltip';
import { catchError, finalize, first, firstValueFrom, of } from 'rxjs';
import { AppState } from '../../ngrx/states/app.state';
import { Store } from '@ngrx/store';
import { selectUserInfoObject } from '../../ngrx/selectors/auth.selector';
import { Permission } from '../../models/permission';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-users-manager',
  standalone: true,
  imports: [ButtonComponent, CommonModule, RoleDropdownComponent, ReusableTableComponent, FormsModule, TooltipModule],
  providers: [],
  templateUrl: './users-manager.component.html',
  styleUrl: './users-manager.component.scss',
})
export class UsersManagerComponent implements OnInit {
  public selectedCollections: any[] = [];
  changes: Map<string, string> = new Map<string, string>();
  atcUrl = environment.atcUrl;
  usersToAdd: Map<string, string> = new Map<string, string>();
  tableData: UserCollectionRoleEntity[] = [];
  selectedRows: UserCollectionRoleEntity[] = [];
  columns: Column[];
  filterFields: string[];
  isLoading = true;
  userEmail: string = '';
  adminEmail: string = '';
  newUserRole: string = 'Select role';
  emailChips: any[] = [];
  emailValid: boolean = false;
  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  removeAccessText: string = 'Remove access';
  addUserText: string = 'Add user';
  cancelText: string = 'Cancel';
  confirmText: string = 'Save';
  removeAccessInProgress: boolean = false;
  addUserInProgress: boolean = false;
  confirmInProgress: boolean = false;
  shouldUpdateTable: boolean = false;
  @Output() changesMade: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('roleTemplate') roleTemplate: TemplateRef<any>;
  @ViewChild('headerTooltipTemplate') headerTooltipTemplate: TemplateRef<any>;
  @ViewChild(ReusableTableComponent) table: ReusableTableComponent;

  constructor(
    public dialogRef: BsModalRef,
    private drawingCloneService: DrawingCloneService,
    private store: Store<AppState>
  ) {
    this.dialogRef.onHidden.subscribe(() => {
      if (this.shouldUpdateTable) {
        this.changesMade.emit();
      }
    });
  }

  async ngOnInit() {
    this.isLoading = true;
    this.store.select(selectUserInfoObject).subscribe((userInfo) => {
      this.adminEmail = userInfo.email;
    });

    this.loadPermissions();
  }

  loadPermissions() {
    this.setEmptyTableData();
    this.isLoading = true;
    const selectedCollectionsGuids = this.selectedCollections.map((collection) => collection.collectionGuid);
    this.drawingCloneService.getPermissionsForCollections(selectedCollectionsGuids).subscribe({
      next: (collectionsWithPermissions) => {
        if (!collectionsWithPermissions.length) {
          this.setEmptyTableData();
        } else {
          this.tableData = collectionsWithPermissions;
          this.filterFields = ['userName', 'userEmail', 'collectionName', 'permissionLevel'];
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.error('An error occurred:', error);
        this.setEmptyTableData();
        this.isLoading = false;
      },
    });
  }

  private setEmptyTableData() {
    this.tableData = [];
  }

  ngAfterViewInit(): void {
    this.columns = [
      { header: 'Name', key: 'userName' },
      { header: 'Email', key: 'userEmail' },
      { header: 'Collection', key: 'collectionName' },
      {
        header: 'Role',
        key: 'permissionLevel',
        template: this.roleTemplate,
        headerTemplate: this.headerTooltipTemplate,
      },
    ];
  }

  onRoleChange(event: any, rowData: any) {
    if (rowData.collectionId === 'PENDING') {
      this.usersToAdd.set(rowData.userEmail, event);
    } else {
      const key = rowData.collectionId + ',' + rowData.userEmail;
      if (event !== rowData.initialPermissionLevel) {
        this.changes.set(key, event);
      } else {
        this.changes.delete(key);
      }
      const index = this.tableData.findIndex((row) => row === rowData);
      this.tableData[index].permissionLevel = event;
    }
  }

  selectionChanged(event: any) {
    this.selectedRows = event;
  }

  hasChanges() {
    return this.changes.size > 0 || this.usersToAdd.size > 0;
  }

  isValidEmail(): boolean {
    const alreadyExists = this.usersToAdd.has(this.userEmail);
    return this.emailRegex.test(this.userEmail.trim()) && !alreadyExists;
  }

  addUser() {
    this.addUserInProgress = true;
    if (this.usersToAdd.has(this.userEmail)) {
      const index = this.tableData.findIndex(
        (row) => row.userEmail === this.userEmail && row.collectionId === 'PENDING'
      );
      this.tableData[index].permissionLevel = this.tableData[index].initialPermissionLevel = this.newUserRole;
      this.usersToAdd.set(this.userEmail, this.newUserRole);
    } else {
      this.drawingCloneService
        .getUserInfo(this.userEmail)
        .pipe(
          first(),
          catchError(() => {
            const email = this.userEmail.trim();
            this.emailChips.push({ displayName: email, email: email, external: false, validUser: false });
            return of(null);
          }),
          finalize(() => {
            this.userEmail = '';
            this.emailValid = false;
            this.addUserInProgress = false;
            this.newUserRole = 'Select role';
          })
        )
        .subscribe((userInfo) => {
          if (userInfo) {
            this.emailChips.push({
              displayName: userInfo.displayName,
              email: this.userEmail.trim(),
              external: this.extractDomain(this.userEmail) !== this.extractDomain(this.adminEmail),
              validUser: true,
            });

            this.tableData.unshift({
              collectionId: 'PENDING',
              collectionName:
                this.selectedCollections.length > 1 ? 'Multiple collections' : this.selectedCollections[0].title,
              userName: userInfo.displayName,
              userId: null,
              userEmail: this.userEmail,
              initialPermissionLevel: this.newUserRole,
              permissionLevel: this.newUserRole,
            });

            this.usersToAdd.set(this.userEmail, this.newUserRole);
          }
        });
    }
  }

  newUserRoleChange(event: any) {
    this.newUserRole = event;
  }

  showExternalEditorWarning(): boolean {
    return (
      Array.from(this.usersToAdd.entries()).filter(
        ([key, value]) => this.extractDomain(key) !== this.extractDomain(this.adminEmail) && value === Role.Editor
      ).length > 0 ||
      Array.from(this.changes.entries()).filter(([key, value]) => {
        const [, email] = key.split(',');
        return this.extractDomain(email) !== this.extractDomain(this.adminEmail) && value === Role.Editor;
      }).length > 0
    );
  }

  extractDomain(email: string): string {
    return email.split('@')[1];
  }

  removePendingUser(email: string) {
    this.usersToAdd.delete(email);
    this.emailChips.splice(
      this.emailChips.findIndex((chip) => chip.email === email),
      1
    );

    const index = this.tableData.findIndex((row) => row.userEmail === email && row.collectionId === 'PENDING');
    if (index !== -1) {
      this.tableData.splice(index, 1);
    }
  }

  async removeAccess() {
    this.removeAccessInProgress = true;
    await this.removePermissionsFromSelectedRows();
    this.loadPermissions();

    this.selectedRows = [];
    this.emailChips = [];
    this.changes.clear();
    this.usersToAdd.clear();
    this.shouldUpdateTable = true;
    this.removeAccessInProgress = false;
  }

  getInvalidUsersCount(): number {
    return this.emailChips.filter((chip) => !chip.validUser).length;
  }

  private async removePermissionsFromSelectedRows() {
    const deleteAssignments = new Map<string, string[]>();
    this.selectedRows.forEach((row) => {
      if (row.collectionId !== 'PENDING') {
        if (!deleteAssignments.has(row.userId)) {
          deleteAssignments.set(row.userId, []);
        }
        deleteAssignments.get(row.userId)?.push(row.collectionId);
      }
    });

    const tasks = Array.from(deleteAssignments.entries()).map(async ([userId, collectionIds]) => {
      try {
        await firstValueFrom(this.drawingCloneService.removePermissions(collectionIds, userId));
      } catch (error) {
        console.error(`Failed to remove permissions for user ${userId}:`, error);
      }
    });

    await Promise.all(tasks);
  }

  async confirmChanges() {
    this.confirmInProgress = true;
    const addAssignments: Map<string, Permission[]> = new Map<string, Permission[]>();
    // Populate addAssignments
    for (const [email, role] of this.usersToAdd.entries()) {
      for (const collection of this.selectedCollections) {
        const newPermission = new Permission();
        newPermission.collectionId = collection.collectionGuid;
        newPermission.permissionLevel = role;
        newPermission.permissionCreation = new Date();

        if (!addAssignments.has(email)) {
          addAssignments.set(email, [newPermission]);
        } else {
          const permissions = addAssignments.get(email);
          permissions.push(newPermission);
        }
      }
    }

    // Await all add operations
    await Promise.all(
      Array.from(addAssignments.entries()).map(async ([key, value]) => {
        try {
          await firstValueFrom(this.drawingCloneService.assignPermissions([key], value));
        } catch (error) {
          console.error('Failed to add permissions for user: ' + key, error);
        }
      })
    );

    const updateAssignments: Map<string, Permission[]> = new Map<string, Permission[]>();
    // Populate updateAssignments
    for (const [rowIdentifier, change] of this.changes.entries()) {
      const [collectionId, email] = rowIdentifier.split(',');

      const newPermission = new Permission();
      newPermission.collectionId = collectionId;
      newPermission.permissionLevel = change;
      newPermission.permissionCreation = new Date();

      if (!updateAssignments.has(email)) {
        updateAssignments.set(email, [newPermission]);
      } else {
        const permissions = updateAssignments.get(email);
        permissions.push(newPermission);
      }
    }

    // Await all update operations
    await Promise.all(
      Array.from(updateAssignments.entries()).map(async ([key, value]) => {
        try {
          await firstValueFrom(this.drawingCloneService.assignPermissions([key], value));
        } catch (error) {
          console.error('Failed to update permissions for user: ' + key, error);
        }
      })
    );

    this.loadPermissions();
    this.changes.clear();
    this.usersToAdd.clear();
    this.emailChips = [];
    this.confirmInProgress = false;
    this.shouldUpdateTable = true;
  }

  cancel() {
    this.dialogRef.hide();
  }

  onClose() {
    this.dialogRef.hide();
  }

  getHeaderText(): string {
    return this.selectedCollections.length > 1
      ? 'Users of the selected collections: Multiple collections'
      : 'Users of the selected collection: ' + this.selectedCollections[0]?.title;
  }

  isPendingRow(rowData: any): string {
    return rowData.collectionId === 'PENDING' || rowData.initialPermissionLevel !== rowData.permissionLevel
      ? 'pending-changes'
      : '';
  }
}
