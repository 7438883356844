import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../ngrx/states/app.state';
import { selectUserInfoObject } from '../ngrx/selectors/auth.selector';
import { Observable, map, of } from 'rxjs';
import { login } from '../ngrx/actions/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, private store: Store<AppState>) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const token = sessionStorage.getItem('bearerToken');

    if (!token) {
      this.store.dispatch(login());
      return of(true);
    }
    return this.store.select(selectUserInfoObject).pipe(
      map((userInfo) => {
        if(next.url[0].path === 'users' && (userInfo && userInfo.organizationRole !== "Admin")) {
          this.router.navigate(['/']);
          return false;
        }
        return true;
      })
    );
  }
}
