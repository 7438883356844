<div class="collections-page">
  <h1>Collections</h1>
  <app-role-switch *ngIf="isSuperAdmin" class="role-switch"></app-role-switch>
  <br />
  <app-reusable-table
    [headerTemplate]="headerTemplate"
    [columns]="columns"
    [data]="collections$ | async"
    [searchEnabled]="true"
    [sortEnabled]="true"
    [paginationEnabled]="true"
    [isLoading]="isLoading"
    (selectedRowsChange)="onSelectedRowsChange($event)"
    [filterFields]="filterFields"
    [tableSize]="'medium'"
    [selectedRows]="selectedRows"
    [isRowDisabled]="isRowDisabled"
    [showSelectAllCheckbox]="!isRowDisabled"
    [rowClassFunction]="isPendingRow"
  >
  </app-reusable-table>
</div>

<!-- Templates Section -->
<ng-template #headerTemplate>
  <div class="collections-page-header">
    <span class="selected-collections-info">{{ selectedRows.length }} collections selected</span>
    <app-button
      [buttonClass]="'btn-primary'"
      [buttonText]="manageUsersText"
      [buttonEnabled]="selectedRows.length > 0 && hasAccessToSelectedRows()"
      (clickEvent)="openUsersManagerDialog()"
    />
    <app-button
      [buttonClass]="'btn-danger'"
      [buttonText]="removeCollectionText"
      [buttonEnabled]="selectedRows.length > 0 && hasAccessToSelectedRows()"
      (clickEvent)="openRemoveCollectionDialog()"
    />
    <app-button
      [buttonClass]="'btn-secondary'"
      [buttonText]="changeAccessText"
      [buttonEnabled]="changes.size > 0"
      (clickEvent)="changeCollectionsAccess()"
    />
  </div>
</ng-template>

<ng-template #accessTemplate let-rowData="rowData">
  <app-access-dropdown 
    [access]="rowData.access"
    [ngClass]="{ 'disabled-dropdown': isRowDisabled(rowData) }"
    (accessChange)="onAccessChange(rowData, $event)"
    [title]="isRowDisabled(rowData) ? 'Only Editors from this collection\'s organisation can change the access level' : ''"
    >
  </app-access-dropdown>
</ng-template>

<ng-template #usersAssignedTemplate let-rowData="rowData">
  <div
    class="collection-icon"
    style="cursor: pointer; background: none; border: none; display: inline-flex; align-items: center"
    [ngClass]="{ 'disabled-button': isRowDisabled(rowData) }"
    (click)="openUsersManagerDialog(rowData)"
    [title]="isRowDisabled(rowData) ? 'Only Editors from this collection\'s organisation can view and manage users for this collection.' : ''"
  >
    <i class="modus-icons notranslate text-primary" aria-hidden="true" style="margin-right: 5px"> person </i>
  </div>
</ng-template>

<ng-template #createdDateFormatTemplate let-rowData="rowData">
  <span>{{ rowData.createdOn | date : 'dd/MM/yyyy HH:mm' }} <br> <small>{{ rowData.createdByUser }}</small></span>
</ng-template>

<ng-template #modifiedDateFormatTemplate let-rowData="rowData">
  <span>{{ rowData.modifiedOn | date : 'dd/MM/yyyy HH:mm' }} <br> <small>{{ rowData.modifiedByUser }}</small></span>
</ng-template>

<ng-template #headerTooltipTemplate let-col>
  <div style="display: flex; align-items: center">
    {{ col.header }}
    <i
      [pTooltip]="tooltipContent"
      tooltipPosition="bottom"
      class="modus-icons"
      aria-hidden="true"
      style="margin-left: 5px; color: #0063a3"
      >info_outlined</i
    >
  </div>
</ng-template>

<ng-template #tooltipContent>
  <div class="flex align-items-center tooltip-content">
    <ul>
      <li>
        <strong>Private:</strong> Only assigned users have access to the collection.
      </li>
      <li>
        <strong>Organization:</strong> All users of the collection's organization have viewer access by default.
      </li>
      <li>
        <strong>Public:</strong> Everyone has viewer access by default. It is visible in Tekla only for users that have the collection id in the environment files.
      </li>
    </ul>
  </div>
</ng-template>