import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'externalText'
})
export class ExternalTextPipe implements PipeTransform {

  transform(value: string, collectionLinkedCompany: string, userCompany: string): string {
    return collectionLinkedCompany === userCompany ? value : `${value} (External)`;
  }

}