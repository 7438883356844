import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { NEVER, Observable, concatMap, filter, map, of, switchMap, catchError } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { reduceJson } from '../helpers/helper.methods';
import { UserInfo } from '../models/user.info';
import { AuthToken } from '../models/auth.token';

@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private location: Location) {}

  /// <summary>
  /// Handle login process. Returns the bearer token on success.
  /// </summary>
  public handleLogin(): Observable<any> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      switchMap(() => this.route.queryParams),
      switchMap((params) => {
        const code: string = params['code'];
        const bearerToken: AuthToken = JSON.parse(sessionStorage.getItem('bearerToken'));
        if (!bearerToken || bearerToken.expiresAt <= Math.round(Date.now() / 1000)) {
          // check if bearer token is expired
          if (!code) {
            // if there is no auth code, redirect user to login page.
            this.authorize();
            return NEVER;
          } else {
            return this.handleToken('authorization_code', code).pipe(
              map((token) => {
                sessionStorage.setItem('bearerToken', JSON.stringify(token));
                const redirectUrl = params['state'] ? params['state'] : '/';
                const url = new URL(redirectUrl, window.location.origin);
                const path = url.pathname;
                const queryParams = {};

                url.searchParams.forEach((value, key) => {
                  queryParams[key] = value;
                });

                this.router.navigate([path], { queryParams });
              })
            );
          }
        } else {
          return of(bearerToken);
        }
      })
    );
  }

  /// <summary>
  /// Redirects the user to the login page.
  /// </summary>
  public authorize(): void {
      let url = `${environment.authenticationURI + '/authorize'}?client_id=${
        environment.clientId
      }&response_type=code&scope=openid%20profile%20email&redirect_uri=${location.origin}`

      if(this.location.path()){
        url += `&state=${this.location.path()}`;
      }

      this.navigate(url);
  }

  /// <summary>
  /// Handle authorization and refresh token exchange
  /// </summary>
  public handleToken(grantType: string, code: string): Observable<any> {
    let body = new HttpParams()
      .set('grant_type', grantType)
      .set(grantType === 'authorization_code' ? 'code' : 'refresh_token', code)
      .set('client_id', environment.clientId)
      .set('client_secret', environment.clientSecret);

    if (grantType === 'authorization_code') {
      body = body.set('redirect_uri', location.origin);
    }

    return this.http
      .post(environment.authenticationURI + '/token', body.toString(), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .pipe(
        map((token) => {
          const bearerToken: AuthToken = {
            accessToken: token['access_token'],
            expiresAt: Math.round(Date.now() / 1000) + token['expires_in'],
            idToken: token['id_token'],
            refreshToken: token['refresh_token'],
          };
          return bearerToken;
        })
      );
  }

  /// <summary>
  /// Get user information
  /// </summary>
  public getUserInfo(): Observable<UserInfo> {
    const bearerToken = JSON.parse(sessionStorage.getItem('bearerToken'));
    return this.http
      .get(environment.authenticationURI + '/userinfo', {
        headers: { Authorization: `Bearer ${bearerToken.accessToken}` },
      })
      .pipe(
        concatMap((userInfoData) => {
          const userInfo = reduceJson(userInfoData) as UserInfo;
          return this.http.get(`${environment.drawingCloneServiceUrl}/User/Role`, {
            headers: { Authorization: `Bearer ${bearerToken.accessToken}` },
          }).pipe(
            map(data => {
              userInfo.organizationRole = (data as any).role;
              userInfo.organizationId = (data as any).companyId;
              userInfo.organizationName = (data as any).company;
              return userInfo;
            }),
            catchError(_ => {
              return of(userInfo);
            })
          );
        })
      );
  }

  /// <summary>
  /// Get ATC user information
  /// </summary>
  public getAtcUser(email: string): Observable<any> {
    const bearerToken = JSON.parse(sessionStorage.getItem('bearerToken'));
    return this.http.get(`${environment.atcUrl}/graph/emailAndProfile('${email}')`, {
      headers: { Authorization: `ID_TOKEN ${bearerToken.idToken}` },
    }).pipe(
      map(data => {
        return !data[0] ? null : {
          displayName: data[0].Profile_displayName,
          email: data[0].EmailAddress_value
        }
      })
    );
  }

  /// <summary>
  /// Navigate to the specified URL. Isolated for testing purposes.
  /// </summary>
  private navigate(url: string): void {
    window.location.href = url;
  }

  public logout(): void {
    const bearerToken = JSON.parse(sessionStorage.getItem('bearerToken'));
    const idToken: string = bearerToken.idToken;
    sessionStorage.removeItem('bearerToken');
    localStorage.removeItem('bearerToken');
    const logoutUrl = `${environment.authenticationURI}/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${location.origin}`;
    this.navigate(logoutUrl);
  }
}
